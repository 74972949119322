<template>
     <div class="home-right">
     	<div>
     		<el-row :gutter="24">
     			<el-col :span="24">
     				<el-row class="choice-box" type="flex" align="middle">
     					<label>目的地</label>
     					<div class="search-inp" id="scrollCity">
     						<el-input v-model="HotelSearchInfo.CityName" size="medium"
     						 style="width:90%" id="scrollCity" @focus="bindShowCity($event)"
								 @input="searchInput" @click.stop.native></el-input>
     					</div>
     				</el-row>
     			</el-col>
     			
     		</el-row>
     		<el-row class="position-r all-box" :gutter="24">
				
				<el-row type="flex" align="middle" style="margin: 0px 0px 0px 10px;">
					<label>入住时间</label>
					<el-date-picker value-format="yyyy-MM-dd" v-model="dates" class="search-time" editable="false" type="daterange"
					 range-separator="至" start-placeholder="入住时间" end-placeholder="退房时间" :picker-options="pickerOptions0"></el-date-picker>
				
					
				</el-row>
				
     			<!-- <el-col :span="12">
     				<el-row class="choice-box" type="flex" align="middle">
     					<label>入住时间</label>
     					<div class="search-inp" @click="showCalendar(0)">
     						<el-date-picker
							      style="width:100%"
     						      v-model="dates"
     						      type="date"
     						      placeholder="选择日期"
								  size="small">
     						    </el-date-picker>
     					</div>
     				</el-row>
     			</el-col>
     			<el-col :span="12">
     				<el-row class="choice-box" type="flex" align="middle">
     					<label class="">离店时间</label>
     					<div class="search-inp" @click="showCalendar(1)">
     					    <el-date-picker
							  style="width:100%"
							  size="small"
     					      v-model="value1"
     					      type="date"
     					      placeholder="选择日期">
     					    </el-date-picker>
     					</div>
     				</el-row>
     			</el-col> -->
     		</el-row>
     		<el-row>
     			 <el-col :span="12">
     			 	<el-row class="choice-box discount-code" type="flex" align="middle">
     			 		<label>房间数</label>
     			 		<div class="choice-select search-inp">
     			 			<el-select placeholder="请选择活动区域" size="medium" style="width:90%" v-model="HotelSearchInfo.RoomCount">
     			 				<el-option v-for="item in Rooms" :key="item.value" :label="item.label" :value="item.value"></el-option>
     			 			</el-select>
     			 		</div>
     			 	</el-row>
     			 </el-col>
     		</el-row>
     		<el-row class="position-r all-box" :gutter="24">
     			<el-col :span="12">
     				<el-row class="choice-box" type="flex" align="middle">
     					<label>每间成人</label>
     					<div class="search-inp" @click="showCalendar(0)">
     						<el-select placeholder="请选择活动区域" size="medium" style="width:48%" v-model="HotelSearchInfo.AdultCount">
     							<el-option v-for="item in adults" :key="item.value" :label="item.label" :value="item.value"></el-option>
     						</el-select>
     						<span class="ml10 gray6">位（18+）</span>
     					</div>
     				</el-row>
     			</el-col>
     			<el-col :span="12">
     				<el-row class="choice-box" type="flex" align="middle">
     					<label>每间儿童</label>
     					<div class="search-inp" @click="showCalendar(0)">
     						<el-select placeholder="请选择活动区域" size="medium" style="width:50%" v-model="HotelSearchInfo.ChildCount">
     							<el-option v-for="item in childrens" :key="item.value" :label="item.label" :value="item.value"></el-option>
     						</el-select>
     						<span class="ml10 gray6">位(0-18)</span>
     					</div>
     				</el-row>
     			</el-col>
     		</el-row>
     		<el-row style="flex" justify="end">
				<el-col :span="24">
					<div class="choice-box discount-code" style="text-align: right;">
						<el-button icon="el-icon-search" @click="bindSearchHotel" type="danger" class="search-btn-box" style="width:30%">搜索</el-button>
					</div>
				</el-col>
			</el-row>
     	</div>
		<NewCity :showCity="showCity" ref="cityAssembly"  @clickCityDel="clickCityDel" @bindSelectCity="bindSelectCity"
			hotcitytype="hotel" :top="y" :left="x" @click.stop.native></NewCity>
     </div>
</template>

<script>	
    import NewCity from '../../../components/newCity/newCity.vue'
	import keywords from '../../Hotel/component/keywords.vue';
	
	import {
		adults,
		childrens,
		HotelSearchInfo
	} from '@/data/staticData';
	import {
		getHotelSearchInfo,
		setHotelSearchInfo
	} from '@/utils/localdatas';
	
	
	var datehelper = require('@/utils/datehelper.js');
	var util = require('@/utils/util.js');
	import {
		GetHotelSearch
	} from '@/api/hotelApi';
	var that;
	
	export default {
		name: 'HomeHotel',
		props: {
			msg: String,
			inputValue: String
		},
		components: {
			NewCity
		},
		data() {
			return {
				adults: adults,
				childrens: childrens,
				showCity: false,
				dates: [],
				Rooms: [{
						value: '1',
						label: '1'
					},
					{
						value: '2',
						label: '2'
					},
					{
						value: '3',
						label: '3'
					},
					{
						value: '4',
						label: '4'
					}
				],
				HotelSearchInfo: HotelSearchInfo,
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				ShowKeyWordBox: false,
				cityCodeKeyWord: '',
				
				value1:'',
				x: 0,
				y: 0,
			};
		},
		created(props) {
			//生命周期里接收参数
			that = this;
			var HotelSearchInfo = getHotelSearchInfo();
			if (HotelSearchInfo == null) {
				getHotelSearchInfoJSON();
			} else {
			
				var date_now = new Date();
				var date_now_str = date_now.toString('yyyy-MM-dd');
				//如果出发日期小于今天
				if (!datehelper.compareDate(HotelSearchInfo.CheckInDateStr, date_now_str)) {
					HotelSearchInfo.CheckInDateStr = datehelper.DateTimeParse(date_now.addDays(5)).toString('yyyy-MM-dd');
					HotelSearchInfo.CheckOutDateStr = datehelper.DateTimeParse(date_now.addDays(10)).toString('yyyy-MM-dd');
				}
			
				that.HotelSearchInfo = HotelSearchInfo;
			}
			that.dates = [HotelSearchInfo.CheckInDateStr, HotelSearchInfo.CheckOutDateStr];
			console.log(that.HotelSearchInfo);
		},
		mounted() {
			// console.log(1);
			window.addEventListener('scroll', that.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		},
		methods: {
			handleScroll() {
				var rect = document.querySelector('#scrollCity').getBoundingClientRect();
			
				this.y = rect.top;
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			bindHotelClick: function() {
				console.log(2222);
				that.showCity = false;
				that.ShowKeyWordBox = false;
			},
			searchInput(e) {
				console.log(e);
				this.$refs.cityAssembly.searchInput(e);
			},
			changeDate() {
				//因为date1和date2格式为 年-月-日， 所以这里先把date1和date2转换为时间戳再进行比较
				let date1 = new Date(this.HotelSearchInfo.CheckInDateStr).getTime();
				let date2 = new Date(this.HotelSearchInfo.CheckOutDateStr).getTime();
				this.pickerOptions0 = {
					disabledDate: time => {
						if (date2 != '') {
							return time.getTime() > Date.now() || time.getTime() > date2;
						} else {
							return time.getTime() > Date.now();
						}
					}
				};
				this.pickerOptions1 = {
					disabledDate: time => {
						return time.getTime() < date1 || time.getTime() > Date.now();
					}
				};
			},
			bindHideKeyWordBox(){
				that.ShowKeyWordBox = false;
			},
			bindShowKeyWordBox(e, flag) {
			
				that.cityCodeKeyWord = that.HotelSearchInfo.Countrycode + that.HotelSearchInfo.Citycode;
				if (that.cityCodeKeyWord == "") {
					that.$alert('请先选择城市');
					return;
				}
				e.currentTarget.select();
				that.showCity = false;
				that.ShowKeyWordBox = flag;
				that.$refs.keywordsRef.bindGetHotelSearchLog(that.cityCodeKeyWord);
			},
			searchKeyWordInput(e) {
				console.log(e);
				that.$refs.keywordsRef.searchKeyWordInput(e);
				// this.$refs.cityAssembly.$emit("searchInput",e);
			},
			//选择关键字
			selectKeyWord(keyWord) {
				console.log(keyWord);
				that.HotelSearchInfo.KeyWord = keyWord;
				that.ShowKeyWordBox = false;
			},
			clickCityDel() {
				this.showCity = false;
			},
			//选择城市
			bindShowCity(e) {
				e.cancelBubble = true;
				e.currentTarget.select();
				that.showCity = true;
				that.ShowKeyWordBox = false;
				var rect = e.target.getBoundingClientRect();
				this.x = rect.left;
				this.y = rect.top;
				console.log(window.pageYOffset + "1");
				console.log(this.y);
			},
			//
			bindSelectCity(cityModel) {
				console.log(cityModel);
				that.HotelSearchInfo.CityName = cityModel.CityName;
				that.HotelSearchInfo.Citycode = cityModel.CityCode;
				that.HotelSearchInfo.Countrycode = cityModel.CountryCode;
				this.showCity = false;
			},			
			//酒店搜索
			bindSearchHotel(e) {
				console.log(that.HotelSearchInfo);
			
				var HotelSearchInfo = that.HotelSearchInfo;
				if (that.dates.length == 0) {
					that.$message.error("请选择入住和离店日期");
					return;
				}
				that.HotelSearchInfo.CheckInDate = that.dates[0];
				that.HotelSearchInfo.CheckInDateStr = that.dates[0];
				that.HotelSearchInfo.CheckOutDate = that.dates[1];
				that.HotelSearchInfo.CheckOutDateStr = that.dates[1];
				GetHotelSearch({
					hotelsearchinfo: JSON.stringify(HotelSearchInfo)
				}, function(result) {
					console.log(result);
					if (result.code == 0) {
						HotelSearchInfo = result.data.HotelSearchInfo;
						console.log(HotelSearchInfo);
						setHotelSearchInfo(that.HotelSearchInfo);
						that.$router.push({
							path: '/Hotel/list'
						});
					} else {
						that.$message.error(result.msg);
					}
				});
			
				// setHotelSearchInfo(that.HotelSearchInfo);
			},
		}
	};

	function getHeadNav(accountno) {
		GetHeadNav(null, function(result) {
			if (result.code == 0) {
				that.headNavs = result.data.headNavs;
			}
		});
	}

	function getMessageList(accountno) {
		let parm = {
			accountno: accountno
			// accountno: 'CMC0K38F',
			// pageindex:that.pageindex
		};
		GetMessageList(parm, function(result) {
			if (result.code == 0) {
				that.noticeCount = result.data.pageCount;
				if (that.noticeCount > 99) {
					that.noticeCount = '99+';
				}
			}
		});
	}

	function getNotice(accountno) {
		GetNotice(null, function(result) {
			if (result.code == 0) {
				that.NoticeText = result.data.NoticeText;
			}
		});
	}
	//获取查询的信息
	function getHotelSearchInfoJSON() {
		GetHotelSearch(null, function(result) {
			console.log(result);
			if (result.code == 0) {
				// console.log(result.data.TicketSearchInfo);
				that.HotelSearchInfo = result.data.HotelSearchInfo;
				setHotelSearchInfo(that.HotelSearchInfo);
				// that.dualType = TicketSearchInfo.Dual;
			}
		});
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	@import '../../../style/home/home.scss';
</style>
