<template>
	<div>
		<div class="home-bg">
			<div>
				<div class="yqfservice-box">
					<div class="container position-r">
						<div class="service-title">为什么选择一起飞?</div>
						<div class="service-box clearfix">
							<div class="service-list">
								<a class="service clearfix" href="http://huodong.yiqifei.com/2013/Service/"
									target="_blank">
									<img src="../../assets/service/serviec01.png" />
									<div class="txtbox">
										<p class="title">全天服务</p>
										<p class="desc">24小时全天候为您服务</p>
									</div>
								</a>
							</div>
							<div class="service-list">
								<a class="service clearfix" href="http://huodong.yiqifei.com/2013/Service/one.html"
									target="_blank">
									<img src="../../assets/service/serviec02.png" />
									<div class="txtbox">
										<p class="title">全程1对1</p>
										<p class="desc">私人管家式全程专人服务</p>
									</div>
								</a>
							</div>
							<div class="service-list">
								<a class="service clearfix" href="http://huodong.yiqifei.com/2013/Service/delate.html"
									target="_blank">
									<img src="../../assets/service/serviec03.png" />
									<div class="txtbox">
										<p class="title">延误有赔</p>
										<p class="desc">免费航班，行李延误赔偿</p>
									</div>
								</a>
							</div>
							<div class="service-list">
								<a class="service clearfix" href="http://huodong.yiqifei.com/2013/Service/seat.html"
									target="_blank">
									<img src="../../assets/service/serviec04.png" />
									<div class="txtbox">
										<p class="title">优选座位</p>
										<p class="desc">提前选好，心仪座位</p>
									</div>
								</a>
							</div>
							<div class="service-list">
								<a class="service clearfix" href="http://huodong.yiqifei.com/2013/Service/orders.html"
									target="_blank">
									<img src="../../assets/service/serviec05.png" />
									<div class="txtbox">
										<p class="title">个性定制</p>
										<p class="desc">量身定做，个性行程</p>
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
				<block v-if="adList!=null">
					<el-carousel direction="horizontal" :autoplay="true" arrow="never">
						<el-carousel-item v-for="(item,index) in adList" :key="index">
							<a target="_blank" :href="item.LinkURL"><img alt="加入一起飞海外回国飞友群" :src="item.FilePath"
									style="width:110%;height:100%" /></a>
						</el-carousel-item>
					</el-carousel>
				</block>
				<el-row class="container home-box2" type="flex" justify="space-between">
					<div class="home-box">
						<div class="">
							<el-row type="flex">
								<div class="home-left">
									<div :class="activeType == 1 ? 'home-airplane-item home-airplane-item-active' : 'home-airplane-item'"
										@click="changeType(1)">
										<span class="iconfont icon-feiji1 icon icon-plan"></span>
										<span>机票</span>
									</div>
									<div :class="activeType == 2 ? 'home-airplane-item home-airplane-item-active' : 'home-airplane-item'"
										@click="changeType(2)">
										<span class="iconfont icon-hotel icon"></span>
										<span>全球酒店</span>
									</div>
								</div>
								<HomeFlight v-if="activeType == 1" ref="FlightAssembly"
									@bindSelectCity="bindSelectCity" />
								<HomeHotel v-if="activeType == 2" />
							</el-row>
						</div>
					</div>
					<div class="easy-order-box">
						<div class="title">简易下单，个性定制</div>
						<div class="sub-title mt5">只要写下您的行程安排，剩下的交给我们来办！</div>
						<el-input type="textarea" :rows="2" placeholder="例如：我们想知道8月8号左右，从广州到纽约，然后8月18号从旧金山到上海的价格。"
							v-model="SimpleOrderText"></el-input>
						<el-row class="mt10" justify="end" type="flex">
							<el-button type="danger" class="search-btn-box" style="width:30%" @click="bindSimpleOrder">
								提交</el-button>
						</el-row>
					</div>
				</el-row>
			</div>
		</div>
		<div class="container">
			<div class="zuixin">
				<!-- 新加部分 -->
				<div class="zuixin-adj">
					<div class="zuixin-gn">特价机票</div>
					<div class="zuixin-danc">
						<div class="zuixin-laba">
							<div class="zuixin-la">
								<img src="../../assets/yiqifeilaba.png" class="zuixin-laa">
							</div>
							<div class="zuixin-dj">大家正在查: </div>
						</div>
						<block v-if="TicketSearchInfoList!=null">
							<MARQUEE scrollAmount=10 scrollDelay=1 direction=left onmouseover="this.stop()"
								onmouseout="this.start()">
								<div class="danc">
									<div class="danca mr10" v-for="(item, index) in TicketSearchInfoList" :key="index"
										@click="bindSearchByTicketSearchList(item)">
										<span
											class="mr5">{{getDataStr(item.TicketSearchInfo.StartDate,'MM月dd日')}}</span>
										<span class="mr5">{{item.TicketSearchInfo.Dual=='0'?'单程':'来回程'}}</span>
										<span class="mr5"
											:title="item.TicketSearchInfo.DepartCityName+'-'+item.TicketSearchInfo.ArrivalCityName">{{item.TicketSearchInfo.DepartCityName}}-{{item.TicketSearchInfo.ArrivalCityName}}</span>
										<span class="mr5">{{formatMsgTime(item.CacheTime)}}</span>
									</div>
								</div>
							</MARQUEE>
						</block>
					</div>
				</div>

				<div class="zuixin-jipiao">
					<div class="zuixin-jj">
						<div class="zuixin-gzz cursor" id="scrollCity_sp" @click="bindShowSelectCity($event, 0, 1)">
							<div class="zuixin-gza">{{SpecialTicketParm.DepartureCityName}}出发</div>
							<div class="zuixin-dw">
								<img src="../../assets/yiqifeidingwei.png" class="zuixin-dwa">
							</div>
						</div>
						<div class="zuixin-gz" @click="isShowMoth=!isShowMoth">
							<div class="zuixin-gza">{{listMontohIndex==-1?'旅行月份':''+(listMontohIndex+1)+'月出发'}}</div>
							<div class="" :class="{'zuixin-xj':!isShowMoth,'zuixin-jx':isShowMoth}">
								<img src="../../assets/yiqifeixia.png" class="zuixin-xja">
							</div>
						</div>
					</div>

					<div class="" :class="(isShowMoth?'chufa':'chufc')">
						<div class="chufaa" v-for="(item,index) in listMontoh" :key="index"
							:class="[listMontohIndex==index?'chufaa':'chufab']" @click="bindSelectMontoh(index)">
							{{item+1}}月
						</div>
					</div>

					<div class="zuixin-hangzhou">
						<div class="zuixin-hangzhoua" v-for="(item, index) in MinPrices" :key="index">
							<div class="zuixin-hz">{{ item.DepatureName }} — {{ item.ArrivalName }}</div>
							<div class="zuixin-yr">{{getDataStr(item.DepatureDate,'MM月dd日')}}</div>
							<div class="zuixin-ligou">
								<div class="zuixin-qi">
									<div class="zuixin-q">¥</div>
									<div class="zuixin-i">{{ item.BasePrice }}</div>
									<div class="zuixin-q">起</div>
								</div>
								<div class="zuixin-lg" @click="bindSearchByMinPrice(item)">立购</div>
							</div>
						</div>
					</div>
				</div>

				<div class="" v-if="HotelList!=null">
					<div class="zuixin-jiudian">
						<div class="zuixin-jd">精选酒店推荐</div>
					</div>
					<div class="zuixin-beijing">
						<div class="" v-for="(item,index) in beij" :key="index"
							:class="[beiji==index?'zuixin-jb':'zuixin-bj']" @click="bttn(index)">
							{{item}}
						</div>
					</div>

					<div class="zuixin-jingxuan" v-if="beiji==0">
						<div class="zuixin-jg" v-for="item in [1,2,3,4,5,6,7,8,9,10]" :key="item">
							<div class="zuixin-jga">
								<img src="../../assets/baoxian05.png" class="zuixin-jga">
							</div>
							<div class="zuixin-xier">
								<div class="zuixin-xe">广州希尔顿逸林酒店</div>
								<div class="zuixin-xx">
									<div class="">
										<el-rate v-model="value1" disabled text-color="#ff9900"
											score-template="{value}"></el-rate>
									</div>
									<div class="zuixin-pf">
										<div class="">&nbsp;&nbsp;评分:&nbsp;</div>
										<div class="">{{value1}}</div>
									</div>
								</div>
								<div class="zuixin-jiage">
									<div class="zuixin-jq">¥</div>
									<div class="zuixin-jqi">4667</div>
									<div class="zuixin-jq">起</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<!-- <div class="zuixin-faxian">
					<div class="zuixin-faxiana">
						<div class="zuixin-fx">发现更多精彩视频文章</div>
						<div class="zuixin-fxa">
							<img src="../../assets/yiqifeixiac.png" class="zuixin-fxb">
						</div>
					</div>
				</div> -->
				<!-- <div style="width:1200px" class="mt10">
					<img src="../../assets/chinesePlane.png" style="width:1200px">
				</div> -->

				<div class="" v-if="ArticleList!=null">
					<div class="zuixin-jiudian">
						<div class="zuixin-jd">发现</div>
					</div>

					<div class="zuixin-jingxuan">
						<div class="zuixin-demo">
							<div class="zuixin-jg" v-for="(item,index) in ArticleList" :key="index"
								@click="bindJumpYouji(item)">
								<div class="zuixin-jgaa">
									<img :src="item.CoverImagePath" class="zuixin-jga">
								</div>
								<div class="zuixin-peiban">
									<div class="zuixin-pb">{{item.Title}}</div>
									<div class="zuixin-touxiang">
										<div class="zuixin-tx">
											<el-avatar size="medium " :src="item.AuthorFacePath">
											</el-avatar>
											<div class="zuixin-txa">{{item.AuthorName}}</div>
										</div>
										<div class="zuixin-tx">
											<div class="zuixin-yj">
												<img src="../../assets/yiqifeiyan.png" class="zuixin-yja">
											</div>
											<div class="zuixin-yjb">{{item.ViewCount}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <el-row class="clearfix mt20" type="flex" justify="space-between"> -->
			<!-- <div class="coupon-news">
					<el-row class="block-title" type="flex" justify="space-between">
						<div>
							<span class="title">优惠资讯</span>
							<a href="javascript:;" :class="'tab-btn ' + (ArticleCategoryID == 1 ? ' active' : '')"
								@click="bindYouhui(1)">最新发表</a>
							<span class="line">|</span>
							<a href="javascript:;" :class="'tab-btn ' + (ArticleCategoryID == 2 ? ' active' : '')"
								@click="bindYouhui(2)">热门发表</a>
						</div>
						<a class="more-link" target="_blank" href="http://www.woquguo.net/">查看更多&gt;</a>
					</el-row>
					<div class="newbox">
						<el-row :gutter="24">
							<el-col :span="12" v-for="(item, index) in YouhuiInfos" :key="index">
								<div class="news-list">
									<a class="imgbox" :href="'http://youji.woquguo.net/Article/' + item.ID"
										target="_blank">
										<img :src="item.CoverImagePath" />
										<div class="text">{{ item.Title }}</div>
									</a>
									<div class="clearfix textbox">
										<a class="avatar">
											<img :src="item.UserImg" />
											<span class="name ml5">{{ item.AuthorName }}</span>
										</a>
										<div class="info">
											<span class="date">{{ item.CreateDate }}</span>
											<a class="iconfont icon-see icon-yanjing ml10">{{ item.ViewCount }}</a>
											<a class="iconfont icon-love icon-aixin ml5">{{ item.PlacetCount }}</a>
											<a
												class="iconfont icon-yqfxiaoxishi icon-comment ml5">{{ item.CommentCount }}</a>
										</div>
									</div>
								</div>
							</el-col>
						</el-row>
					</div>
				</div> -->

			<!-- <div class="allpeople-searching">
					<div class="view-hd">大家正在查</div>

					<superslide :options="options" class="slideBox" v-if="TicketSearchInfoList!=null">
						<a class="prev" href="javascript:void(0)" slot="prev"><span
								class="iconfont icon-xiala icon-arrow"></span></a>
						<div class="searching-box">
							<div class="bd" solt="mainCell">
								<ul>
									<li class="list" style="list-style: none; display: block; clear: both;"
										v-for="(item, index) in TicketSearchInfoList" :key="index" @click="bindSearchByTicketSearchList(item)">
										<p class="title clearfix">
											<span class="fl mr5">{{getDataStr(item.TicketSearchInfo.StartDate,'MM月dd日')}}</span>
											<span class="fl mr5">{{item.TicketSearchInfo.Dual=='0'?'单程':'来回程'}}</span>
											<span class=" fl city text-over"
												:title="item.TicketSearchInfo.DepartCityName+'-'+item.TicketSearchInfo.ArrivalCityName">{{item.TicketSearchInfo.DepartCityName}}-{{item.TicketSearchInfo.ArrivalCityName}}</span>
										 </p>
										<p class="time">{{formatMsgTime(item.CacheTime)}}</p>
									</li>
								</ul>
							</div>
						</div>
						<a class="next" href="javascript:void(0)" slot="next"><span
								class="iconfont icon-xiala-copy icon-arrow"></span></a>
						<span class="pageState" slot="pageStateCell" style="display: none;">></span>
					</superslide>

					<div class="hd" slot="titCell" style="display: none;">
						<ul>
							<li class="on">1</li>
							<li class="">2</li>
							<li class="">3</li>
							<li class="">2</li>
							<li class="">3</li>
							<li class="">3</li>
						</ul>
					</div>
				</div> -->
			<!-- </el-row> -->
		</div>

		<!--在线旅行顾问-->
		<div class="trip-wrap mt20">
			<div class="container clearfix">
				<div class="tripadvisor" v-if="StaffContacts!=null">
					<div class="hd">
						<div class="title">在线旅行顾问推荐</div>
						<div class="desc">无论你在哪个城市，旅行顾问会随时随地帮你搞定一切</div>
					</div>

					<div class="bd" id="div_advisor">
						<div :class="'advisor-card advisor0' + (index + 1)" v-for="(item, index) in StaffContacts"
							:key="index">
							<div class="inner clearfix">
								<a class="avatar fl" :href="'https://m.yqf.com/homepage/adviserHome/'+(item.UserCode)"
									target="_blank"><img :src="item.UserImg" /></a>
								<div class="text">
									<p class="ft14">
										{{ item.StaffName }}
										<!--  男女符号图标 附加类 男icon-boy    女icon-gril -->
										<i
											:class="'iconfont icon-nv ' + (item.Sex == 1 ? 'icon-boy' : 'icon-girl')"></i>
										<span class="ml10 assess">
											<em class="fb">{{ item.UserAVGScore }}</em>
											分
										</span>
									</p>
									<p>精通领域：{{ item.JingTong }}</p>
								</div>
							</div>
							<div class="clearfix contact-box">
								<div class="fl">
									<p>
										手机号码：
										<em class="gray9">{{ item.Phone }}</em>
									</p>
									<p>
										电子邮箱：
										<em class="gray9">{{ item.Email }}</em>
									</p>
								</div>
								<div class="code-box fr"><img class="code" alt="" :src="item.QrcodeImg" /></div>
							</div>
							<div class="score clearfix">
								<div class="fl text-center sub-block">
									<p class="ft16 num">{{ item.UserServiceCount.CustomerServiceCount }}</p>
									<p>服务客户数</p>
								</div>
								<div class="fl text-center sub-block">
									<p class="ft16 num">{{ item.UserServiceCount.FormulaCount }}</p>
									<p>方案数</p>
								</div>
								<div class="fl text-center sub-block">
									<p class="ft16 num">{{ item.CommentCount }}</p>
									<p>点评数</p>
								</div>
							</div>
						</div>
					</div>
					<div v-if="AdvisorCount>0" class="more-advisor" onclick="window.open('http://guwen.woquguo.net/')">
						还有
						<em class="num">{{ AdvisorCount }}+</em>
						旅行顾问
					</div>
				</div>

				<div class="tripadvisor-news" v-if="FreshInfos!=null&&FreshInfos.length>0">
					<div class="text-center" style="text-align: center;padding:20px;"><span
							class="title ft18 ">最近发生</span></div>
					<superslide :options="options2" class="slideBox2">
						<div class="bd" solt="mainCell">
							<ul>
								<li class="mb20" v-for="(item, index) in FreshInfos" :key="index">
									<p class="clearfix mb5">
										<a class="avatar fl" target="_blank"
											:href="'https://m.yqf.com/homepage/adviserHome/'+(item.UserCode)">
											<img :src="item.UserImg" />
										</a>
										<span class="fl ml10 mr5 time">{{item.TimeEXSpan}}</span>
										<a class="namebox fl">
											旅行顾问
											<span class="name">{{item.StaffName}}</span>
											为旅友预订
										</a>
									</p>
									<p class="clearfix">
										{{item.newsInfoStr}}

									</p>
								</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
								<li v-if="FreshInfos.length==0" style="height:84.2px">1</li>
							</ul>
						</div>
					</superslide>
					<div class="hd" slot="titCell" style="display: none;">
						<ul>
							<li>1</li>

						</ul>
					</div>
				</div>
			</div>
		</div>
		<!--隐藏部分-->
		<div class="container" v-show="false">
			<div class="travels-box mt20">
				<div class="hd">
					<h3 class="title">旅游特卖</h3>
					<p class="desc">一起飞为您打造完美旅程，超值自由行旅游线路任您选择，省钱更省心</p>
				</div>
				<div class="bd">
					<el-row :gutter="24">
						<el-col class="list" :span="12" v-for="(item, index) in [1, 2, 3, 4]" :key="index">
							<el-row class="list-content" type="flex">
								<div class="peview">
									<div><img
											src="http://img8.yiqifei.com/20170605/c2bd56d0ccf94802a19dcda8d25f566e.jpg!230x170" />
									</div>
								</div>
								<div class="right-info">
									<h4 class="ft18 text-over mb5" style="cursor: pointer;">我国自主研制C919大型客机在上海圆满首飞</h4>
									<div class="txt mb10">
										<i class="icon-quote icon mr5"></i>
										我国自主研制C919大型客机在上海圆满首飞
									</div>
									<div class="textbox clearfix ">
										<div class="avatar">
											<img
												src="http://img2.yiqifei.com/20170119/11f0b0cb195d4ce388d9afecf86455ff.jpg!80" />
											<span class="ml5 name">周超龙</span>
										</div>
										<span class="date ml10 ">2017-05-05</span>
										<div class="info"><span class="iconfont icon-you icon-arrow-right ml10"></span>
										</div>
									</div>
								</div>
							</el-row>
						</el-col>
					</el-row>
				</div>
				<div class="text-center mt15 more-link-box"><a class="more-link" href="http://youji.woquguo.net/"
						target="_blank">查看更多</a></div>
			</div>
		</div>
		<!--隐藏部分-->
		<div class="trip-sale-wrap mt20" v-show="false">
			<div class="container">
				<div class="trip-sale mod-list  pb10">
					<div class="hd">
						<h3 class="title">旅游特卖</h3>
						<p class="desc">一起飞为您打造完美旅程，超值自由行旅游线路任您选择，省钱更省心</p>
					</div>
					<div class="bd">
						<el-row :gutter="24">
							<el-col class="list" v-for="(item, index) in [1, 2, 3, 4]" :key="index" :span="8">
								<div class="list-content">
									<div><img class="pic"
											src="http://img8.yiqifei.com/20170605/c2bd56d0ccf94802a19dcda8d25f566e.jpg!230x170" />
									</div>
									<div class="textbox">
										<p class="txt">香港海洋公园门票（成人好友门票）</p>
										<p>
											<span class="price fl">
												&yen;
												<em>1000</em>
												起
											</span>
											<a class="iconfont icon-you icon-arrow-right ml10 fr"></a>
										</p>
									</div>
								</div>
							</el-col>
						</el-row>
						<div class="text-center mt20 more-link-box"><a class="more-link"
								href="http://tour.yiqifei.com/Vacation" target="_blank">查看更多</a></div>
					</div>
				</div>
			</div>
		</div>

		<!--提示-->
		<el-dialog class="des-modal" :visible.sync="showSimpleOrder" show-close="true" title="提示" left>
			<div class="text-c">
				<iframe style="overflow:hidden;height:650px; width:680px;" :src="SimpleOrderUrl"
					frameborder="0"></iframe>
			</div>
		</el-dialog>

		<!-- <NewCity :showCity="showCity" ref="cityAssembly" @clickCityDel="clickCityDel" @bindSelectCity="bindSelectCity"
			hotcitytype="air" :top="y" :left="x" @click.stop.native></NewCity> -->
	</div>
</template>

<script>
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');
	var datehelper = require('@/utils/datehelper.js');
	var staticData = require('@/data/staticData.js');
	import {
		getTicketSearchInfo,
		setTicketSearchInfo
	} from '@/utils/localdatas';

	//接口
	var flightApi = require('@/api/flightApi.js');
	var wqgApi = require('@/api/wqgApi.js');
	var yqfApi = require('@/api/yqfApi.js');
	var commonApi = require('@/api/commonApi.js');

	import HomeFlight from './components/flight.vue';
	import HomeHotel from './components/hotel.vue';

	var that;
	import VueSuperSlide from 'vue-superslide';
	// import NewCity from '@/components/newCity/newCity.vue';
	export default {
		name: 'Home',
		components: {
			HomeFlight,
			HomeHotel,
			// NewCity
		},
		data() {
			return {
				titile: '首页',
				imgItem: [{
					img: 'https://yx.yiqifei.com:443/ADMedias/20200813/895898e8ced64d2da61d98c884565c44.jpg',
					link: 'https://mp.weixin.qq.com/s/59LWxO6baLFE-CKTCjKDrw'
				}],
				adList: [{
					LinkURL: 'https://mp.weixin.qq.com/s/59LWxO6baLFE-CKTCjKDrw',
					FilePath: 'https://yx.yiqifei.com:443/ADMedias/20200813/895898e8ced64d2da61d98c884565c44.jpg',
					Subject: ''
				}], //首页广告
				tabPosition: 'left',
				activeName: 'second',
				TicketSearchInfo: staticData.TicketSearchInfo,
				activeType: 1,
				options: {
					titCell: '.hd ul',
					mainCell: '.bd ul',
					autoPlay: true,
					autoPage: true,
					effect: 'top',
					vis: '6'
				},
				options2: {
					titCell: '.hd ul',
					mainCell: '.bd ul',
					autoPlay: true,
					autoPage: true,
					effect: 'top',
					vis: '3'
				},
				ArticleCategoryID: 1,
				YouhuiInfos: [], //优惠资讯
				SearchingInfos: [],
				StaffContacts: null,
				AdvisorCount: 0,
				FreshInfos: [],
				TicketSearchInfoList: null, //大家正在查
				//简易下单
				showSimpleOrder: false,
				SimpleOrderUrl: 'https://diy.yiqifei.com/AddSimpleOrderByIframe.aspx',
				SimpleOrderText: '',

				// chufa: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
				// yuefen: 0,
				// beij: ['北京', '上海', '广州', '深圳', '杭州', '成都'],
				// beiji: 0,				
				value1: 4.65,

				isShowMoth: false,
				SpecialTicketParm: {
					DepartureCityCode: 'CAN',
					DepartureCityName: '广州',
					ArrivalCityCode: '',
					ArrivalCityName: '',
					LabelID: ''
				},
				weekArry: ['日', '一', '二', '三', '四', '五', '六'],
				weekIndex: -1,
				listMontoh: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
				listMontohIndex: -1,
				MinPrices: null, //特价机票
				ArticleList: null, //游记文章
				HotelList: null, //精选酒店
				showCity: false,
				x: 0,
				y: 0,
			};
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			getAdList();
			// GetYouhuiInfo();
			// GetAdvisorInfo();
			GetOnlineStaffProfiles(); //在线旅行顾问
			GetFreshInfos(); //新鲜事
			serviceGo();
			GetSearchingInfo();
			getRouteMinPrice();
			GetArticleListByUserCode();
			if (!window.localStorage) {
				console.log('浏览器不支持localStorage');
			}
		},
		methods: {
			bindShowSelectCity(e) {
				this.$refs.FlightAssembly.bindShowSelectCity(e, -1);
			},
			//选择城市
			bindShowCity(e, cityIdx, cityType) {
				if (that.TicketSearchInfo.IsChange) {
					return;
				}

				// that.cityIdx = cityIdx;
				// that.cityType = cityType;
				this.showCity = true;
				var rect = e.target.getBoundingClientRect();
				// console.log(rect);
				that.x = rect.left;
				that.y = rect.top;
				// console.log(window.pageYOffset + '1');
				// console.log(this.y);
				e.currentTarget.select();
			},
			clickCityDel() {
				this.showCity = false;
			},
			//选择城市
			bindSelectCity(cityModel) {
				that.showCity = false;

				if (cityModel == null) {
					return;
				}
				that.SpecialTicketParm.DepartureCityCode = cityModel.CityCode;
				that.SpecialTicketParm.DepartureCityName = cityModel.CityName;
				getRouteMinPrice();

				// var cityIdx = that.cityIdx;
				// var cityType=that.cityType;
				// console.log(cityIdx);
				// console.log(cityType);

				// var TicketSearchInfo = that.TicketSearchInfo;
				// that.TicketSearchInfo=ticketSearchHelper.SelectCity(TicketSearchInfo,cityType,cityIdx,cityModel);
			},
			//游记跳转
			bindJumpYouji: function(e) {
				// window.open("http://youji.woquguo.net/Article/" + e.ID + "");
				window.open("#/youji/article?id=" + e.ID + "");
			},
			//选择几月
			bindSelectMontoh: function(e) {
				console.log(e);

				var listMontohIndex = e;
				if (that.listMontohIndex == listMontohIndex) {
					listMontohIndex = -1;
				}

				that.listMontohIndex = listMontohIndex;
				getRouteMinPrice();
			},
			//特价机票查询
			bindSearchByMinPrice(e) {
				// var e = e.currentTarget.dataset.dataitem;
				console.log(e);
				// return;
				let TicketSearchInfo = that.TicketSearchInfo;
				TicketSearchInfo.StartDate = e.DepatureDate;
				TicketSearchInfo.ReturnDate = e.ReturnDate;
				TicketSearchInfo.DepartCityCode = e.Depature;
				TicketSearchInfo.DepartCityName = e.DepatureName;
				TicketSearchInfo.ArrivalCityCode = e.Arrival;
				TicketSearchInfo.ArrivalCityName = e.ArrivalName;
				getTicketSearch();
				// console.log(TicketSearchInfo);
				// // return;
				// setTicketSearchInfo(TicketSearchInfo);
				// if (TicketSearchInfo.IsChinaCity) {
				// 	that.$router.push({
				// 		path: '/Flight/ShowDomesticFlight'
				// 	});
				// } else {
				// 	that.$router.push({
				// 		path: '/Flight/showIntlflight'
				// 	});
				// }
			},
			getDataStr(date, str) {
				if (yqfCommon.isNullOrEmpty(date))
					return;
				let _date = datehelper.DateTimeParse(date);
				return _date.toString(str);
			},
			formatMsgTime: function(e) {
				return datehelper.formatMsgTime(e);
			},
			//简易下单
			bindSimpleOrder: function(e) {
				if (yqfCommon.isNullOrEmpty(that.SimpleOrderText)) {
					that.$alert('请填写您的需求');
					return;
				}
				that.SimpleOrderUrl = that.SimpleOrderUrl + "?w=" + that.SimpleOrderText;
				that.showSimpleOrder = true;
			},
			bindYouhui: function(e) {
				that.ArticleCategoryID = e;
				GetYouhuiInfo();
			},
			handleClick(tab, event) {
				console.log(tab, event);
			},
			changeType(e) {
				console.log(1);
				that.activeType = e;
			},
			//历史搜索
			bindSearchByTicketSearchList: function(e) {
				console.log(e);
				let TicketSearchInfo = e.TicketSearchInfo;
				setTicketSearchInfo(TicketSearchInfo);
				if (TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/ShowDomesticFlight'
					});
				} else {
					that.$router.push({
						path: '/Flight/showIntlflight'
					});
				}

			}
		}
	};
	//查询
	function getTicketSearch() {
		// util.showLoding();
		that.TicketSearchInfo.Airline = '';
		flightApi.GetTicketSearch({
				ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
			},
			function(result) {
				// uni.hideLoading();
				// console.log(result);
				if (result.code == 0) {
					var TicketSearchInfo = result.data.TicketSearchInfo;
					console.log(TicketSearchInfo);
					setTicketSearchInfo(TicketSearchInfo);
					if (TicketSearchInfo.IsChinaCity) {
						that.$router.push({
							path: '/Flight/ShowDomesticFlight'
						});
					} else {
						that.$router.push({
							path: '/Flight/showIntlflight'
						});
					}
				} else {
					that.$alert(result.msg);
					// util.wxalert(result.msg);
				}
			}
		);
	}
	//优惠信息
	function GetYouhuiInfo() {
		var parm = {
			ArticleCategoryID: that.ArticleCategoryID
		};
		yqfApi.GetYouhuiInfo(parm, function(result) {
			// console.log(result);
			if (result.code == 0) {
				that.YouhuiInfos = result.data.YouhuiInfos;
			} else {
				// that.$message.error(result.msg);
			}
		});
	}
	//营业员信息
	function GetAdvisorInfo() {
		var parm = {};
		yqfApi.GetAdvisorInfo(parm, function(result) {
			// console.log(result);
			if (result.code == 0) {
				that.AdvisorInfos = result.data.AdvisorInfos;
				that.FreshInfos = result.data.FreshInfos;
				that.AdvisorCount = result.data.AdvisorCount;
				// FreshInfos=that.FreshInfos

			} else {
				// that.$message.error(result.msg);
			}

		});
	}
	//获取在线旅游顾问
	function GetOnlineStaffProfiles() {
		var parm = {};
		wqgApi.GetOnlineStaffProfiles(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.StaffContacts = result.data.StaffContacts;
				that.AdvisorCount = result.data.AdvisorCount;
			} else {
				// that.$message.error(result.msg);
			}
		});
	}
	//新鲜事
	function GetFreshInfos() {
		var parm = {};
		wqgApi.GetFreshInfos(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.FreshInfos = result.data.FreshInfos;
			} else {
				// that.$message.error(result.msg);
			}

		});
	}
	//大家正在查
	function GetSearchingInfo() {
		var parm = {};
		flightApi.GetSearchingInfo(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.TicketSearchInfoList = result.data.TicketSearchInfoList;

			} else {
				// that.$message.error(result.msg);
			}

		});
	}
	//获取广告
	function getAdList() {
		// uni.showLoading({
		//   title: '加载中',
		// });		
		let parm = {
			id: 118
		};
		commonApi.GetAdList(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.adList = result.data.adList;
			} else {
				// util.wxalert(result.msg);
			}
		});
	}
	//特价机票
	function getRouteMinPrice() {
		var parm = {
			Departure: that.SpecialTicketParm.DepartureCityCode,
			// TripType:that.ticketSearchInfo.Dual,
			// Arrivals: that.SpecialTicketParm.ArrivalCityCode,
			week: that.weekIndex,
			month: that.listMontohIndex
		};
		flightApi.GetRouteMinPrice(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.MinPrices = result.data.MinPrices;
				// getRouteMinPriceByLabelID();
			}
		});
	}
	//文章列表
	function GetArticleListByUserCode() {
		var parm = {

		};
		wqgApi.GetArticleListByUserCode(parm, function(result) {
			// console.log(result);;
			if (result.code == 0) {
				that.ArticleList = result.data.ArticleList;
				// getRouteMinPriceByLabelID();
			}
		});
	}

	function serviceGo() {
		$('.yqfservice-box .service-list,.yqfservice-box .service-title').hide();
		setTimeout(function() {
			$('.yqfservice-box .service-title')
				.show()
				.animate({
						left: '40%'
					},
					2000
				);
		}, 200);
		setTimeout(function() {
			$('.yqfservice-box .service-title').animate({
					left: '-40%'
				},
				1000
			);
		}, 4300);
		setTimeout(function() {
			$('.yqfservice-box .service-list:eq(0)')
				.show()
				.animate({
						marginLeft: '0'
					},
					2000
				);
		}, 4000);
		setTimeout(function() {
			$('.yqfservice-box .service-list:eq(1)')
				.show()
				.animate({
						marginLeft: '0'
					},
					2000
				);
		}, 4500);
		setTimeout(function() {
			$('.yqfservice-box .service-list:eq(2)')
				.show()
				.animate({
						marginLeft: '0'
					},
					2000
				);
		}, 5000);
		setTimeout(function() {
			$('.yqfservice-box .service-list:eq(3)')
				.show()
				.animate({
						marginLeft: '0'
					},
					2000
				);
		}, 5500);
		setTimeout(function() {
			$('.yqfservice-box .service-list:eq(4)')
				.show()
				.animate({
						marginLeft: '0'
					},
					2000
				);
		}, 5800);
	}

	// 定义瀑布流算法函数
	function fall() {
		const minGap = 20; // 最小间距，让每一列的最小空隙可以自定义，避免太过拥挤的情况发生。但是，会通过计算得到真实的间距。
		const itemWidth = 300; // 每一项的宽度，即当前每一个图片容器的宽度。保证每一列都是等宽不等高的。
		const scrollBarWidth = getScrollbarWidth(); // 获取滚动条的宽度
		const pageWidth = window.innerWidth - scrollBarWidth; // 获取当前页面的宽度 = window.innerWidth - 滚动条的宽度
		const column = Math.floor(pageWidth / (itemWidth + minGap)); // 实际列数=页面宽度/(图片宽度+最小间距)
		const gap = (pageWidth - itemWidth * column) / column / 2; // 计算真实间距 = (页面宽度- 图片宽度*实际列数)/实际列数/2
		const items = document.querySelectorAll('img'); // 获取所有的外层元素
		const heightArr = []; // 定义一个空数组，保存最低高度。

		// 获取滚动条的宽度
		function getScrollbarWidth() {
			const oDiv = document.createElement('div'); //创建一个div
			// 给div设置样式。随便定义宽高，只要能获取到滚动条就可以
			oDiv.style.cssText = `width: 50px;height: 50px;overflow: scroll;`
			document.body.appendChild(oDiv); //把div添加到body中
			const scrollbarWidth = oDiv.offsetWidth - oDiv.clientWidth; // 使最大宽度和可视宽度相减，获得到滚动条宽度。
			oDiv.remove(); //移除创建的div
			return scrollbarWidth; //返回滚动条宽度
		}


		for (let i = 0; i < items.length; i++) {
			// 遍历所有的外层容器
			const height = items[i].offsetHeight;
			// 如果当前处在第一行
			if (i < column) {
				// 直接设置元素距离上部的位置和距离左边的距离。
				items[i].style.cssText = `top: ${gap}px;left: ${(itemWidth + gap) * i + gap}px`;
				// 保存当前元素的高度。
				heightArr.push(height);
			} else {
				// 不是第一行的话，就进行比对。
				let minHeight = heightArr[0]; // 先保存第一项的高度
				let minIndex = 0; // 保存第一项的索引值
				for (let j = 0; j < heightArr.length; j++) {
					// 通过循环遍历比对，拿到最小值和最小值的索引。
					if (minHeight > heightArr[j]) {
						minHeight = heightArr[j];
						minIndex = j;
					}
				}
				// 通过最小值为当前元素设置top值，通过索引为当前元素设置left值。
				items[i].style.cssText = `top: ${minHeight + gap *2}px; left: ${(itemWidth + gap) * minIndex + gap}px`;
				// 并修改当前索引的高度为当前元素的高度
				heightArr[minIndex] = minHeight + gap + height;
			}
		}
	}
	// 页面加载完成调用一次。
	window.onload = fall;
	// 页面尺寸发生改变再次调用。
	window.onresize = fall;
</script>

<style lang="scss">
	@import '../../style/home/home.scss';

	// body {
	// 		filter: grayscale(100%);
	// 		-moz-filter: grayscale(100%);
	// 		-o-filter: grayscale(100%);
	// 		-webkit-filter: grayscale(1);
	// 	}
</style>
