<template>
	<div class="home-right">
		<el-tabs @tab-click="handleClick" v-model="TicketSearchInfo.Dual">
			<el-tab-pane label="单程" name="0" class="int-serch"></el-tab-pane>
			<el-tab-pane label="双程" name="1" class="int-serch"></el-tab-pane>
			<el-tab-pane label="多航段" name="2" class="int-serch"></el-tab-pane>
		</el-tabs>

		<!--单双程-->
		<div class="find-con clearfix" v-if="TicketSearchInfo.Dual != 2">
			<div class="reg-box1 block">
				<el-row class="position-r all-box" :gutter="24">
					<el-col :span="12">
						<el-row class="choice-box" type="flex" align="middle">
							<label>出发城市</label>
							<div class="search-inp" id="scrollCity">
								<el-input v-model="TicketSearchInfo.DepartInputVal" size="medium"
									suffix-icon="iconfont icon-chalv-chufa icon-down" style="width:90%"
									@focus="bindShowCity($event, 0, 0)" @input="searchInput" @click.stop.native>
								</el-input>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="choice-box" type="flex" align="middle">
							<label>出发日期</label>
							<div class="search-inp" @click="showCalendar(0)">
								<el-date-picker type="date" size="small" placeholder="选择日期" style="width:91%"
									value-format="yyyy-MM-dd" v-model="TicketSearchInfo.StartDate"
									:picker-options="pickerOptions0"></el-date-picker>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="choice-box" type="flex" align="middle">
							<label>抵达城市</label>
							<div class="search-inp" id="scrollCity1">
								<el-input v-model="TicketSearchInfo.ArrivalInputVal" size="medium"
									suffix-icon="iconfont icon-chalv-daoda icon-down" style="width:90%"
									@focus="bindShowCity($event, 0, 1)" @input="searchInput" @click.stop.native>
								</el-input>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="choice-box" type="flex" align="middle">
							<label class="">回程日期</label>
							<div class="search-inp" @click="showCalendar(1)">
								<el-date-picker style="width:90%" size="medium" value-format="yyyy-MM-dd" type="date"
									placeholder="yyyy-MM-dd" v-model="TicketSearchInfo.ReturnDate"
									:picker-options="pickerOptions1" :disabled="TicketSearchInfo.Dual == 0">
								</el-date-picker>
							</div>
						</el-row>
					</el-col>

					<span class="int-transform" @click="bindQieHuan(0)"></span>
					<el-col :span="12">
						<el-row class="choice-box discount-code" type="flex" align="middle">
							<span>舱位选择</span>
							<div class="choice-select search-inp">
								<el-select placeholder="请选择活动区域" size="medium" style="width:90%"
									v-model="TicketSearchInfo.Cabin">
									<el-option v-for="(item,index) in canbins" :key="index" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="choice-box discount-code" type="flex" align="middle">
							<span>折扣代码</span>
							<div class="search-inp">
								<el-input v-model="TicketSearchInfo.DC_Code" size="medium" style="width:91%"></el-input>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<el-row class="choice-box discount-code" type="flex" align="middle">
							<div class="advisor-service">
								<a @click="bindShowIM" href="javascript:;"><img
										src="//tools.yiqifei.com/images/service/ly-zx.gif" /></a>

								<p class="gray6 ft12" id="obj_vistinfo"></p>
							</div>
						</el-row>
					</el-col>
					<el-col :span="12">
						<div class="choice-box discount-code" style="text-align: center;">
							<el-button icon="el-icon-search" type="danger" class="search-btn-box" @click="bindSearch">
								搜索机票</el-button>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!--多航段-->
		<div v-if="TicketSearchInfo.Dual == 2">
			<div v-for="(item, index) in TicketSearchInfo.MultSegments" :key="index">
				<el-row type="flex" justify="space-between">
					<div>
						<span class="iconfont icon-feiji1 icon-plane"></span>
						<span class="flight-red">航段{{ index + 1 }}</span>
					</div>
					<div><span class="iconfont icon-chalv-guanbi icon-guanbi" @click="bindDelSegment(index)"></span>
					</div>
				</el-row>

				<div class="city-box">
					<el-row>
						<span>出发城市</span>
						<div class="search-inp" :id="'scrollCity' + index">
							<el-input v-model="item.DepartInputVal" size="medium"
								suffix-icon="iconfont icon-chalv-chufa icon-down" style="width:90%"
								@focus="bindShowCity($event, index, 0)" @input="searchInput" @click.stop.native>
							</el-input>
						</div>
					</el-row>

					<el-row>
						<span>抵达城市</span>
						<div class="search-inp" :id="'scrollCity1' + index">
							<el-input v-model="item.ArrivalInputVal" size="medium"
								suffix-icon="iconfont icon-chalv-daoda icon-down" style="width:90%"
								@focus="bindShowCity($event, index, 1)" @input="searchInput" @click.stop.native>
							</el-input>
						</div>
					</el-row>

					<el-row>
						<span>出发日期</span>
						<div class="search-inp" @click="showCalendar(1)">
							<el-date-picker style="width:90%" size="medium" type="date" value-format="yyyy-MM-dd"
								placeholder="yyyy-MM-dd" v-model="item.DepartDate" :picker-options="pickerOptions0">
							</el-date-picker>
						</div>
					</el-row>
				</div>
			</div>

			<div class="add-route-btn" @click="bindAddSegment">添加一个航段</div>

			<el-row>
				<el-col :span="12">
					<el-row class="choice-box discount-code" type="flex" align="middle">
						<span>舱位选择</span>
						<div class="choice-select search-inp">
							<el-select placeholder="请选择活动区域" size="medium" style="width:90%"
								v-model="TicketSearchInfo.Cabin">
								<el-option v-for="(item,index) in canbins" :key="index" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</div>
					</el-row>
				</el-col>
				<el-col :span="12">
					<el-row class="choice-box discount-code" type="flex" align="middle">
						<span>折扣代码</span>
						<div class="search-inp">
							<el-input v-model="TicketSearchInfo.DC_Code" size="medium" style="width:90%"></el-input>
						</div>
					</el-row>
				</el-col>
				<el-col :span="12">
					<el-row class="choice-box discount-code" type="flex" align="middle">
						<div class="advisor-service">
							<a href="/AjaxData/JumpToFeiFei" target="_blank"><img
									src="//tools.yiqifei.com/images/service/ly-zx.gif" /></a>

							<p class="gray6 ft12" id="obj_vistinfo"></p>
						</div>
					</el-row>
				</el-col>
				<el-col :span="12">
					<div class="choice-box discount-code" style="text-align: center;">
						<el-button icon="el-icon-search" type="danger" class="search-btn-box" @click="bindSearch">搜索机票
						</el-button>
					</div>
				</el-col>
			</el-row>
		</div>

		<NewCity :showCity="showCity1" ref="cityAssembly" @clickCityDel="clickCityDel" @bindSelectCity="bindSelectCity"
			hotcitytype="air" :top="y" :left="x" @click.stop.native></NewCity>
	</div>
</template>

<script>
	import {
		getTicketSearchInfo,
		setTicketSearchInfo
	} from '@/utils/localdatas';
	import {
		duals,
		canbins,
		adults,
		childrens,
		TicketSearchInfo
	} from '@/data/staticData';
	import {
		getAccountInfo,
		setAccountInfo,
		clearLoginInfo
	} from '@/utils/localdatas';
	import {
		GetHeadNav,
		GetMessageList,
		GetNotice
	} from '@/api/memberApi';
	import NewCity from '../../../components/newCity/newCity.vue';

	var webconfig = require('@/utils/webconfig.js');
	var datehelper = require('@/utils/datehelper.js');
	var ticketSearchHelper = require('@/utils/ticketSearchHelper.js');
	//接口
	var destApi = require('@/api/destApi.js');
	var flightApi = require('@/api/flightApi.js');

	var that;
	export default {
		name: 'HomeFlight',
		props: {
			msg: String,
			inputValue: String
		},
		components: {
			NewCity
		},
		data() {
			return {
				TicketSearchInfo: TicketSearchInfo,
				Duals: duals,
				canbins: canbins,
				adults: adults,
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				airlineList: [],

				tabPosition: 'left',
				activeName: 'second',
				value1: '',
				showCity1: false,
				showDate: false,
				cityIdx: 0,
				cityType: 0,
				dateIdx: 0,
				type: 1,
				x: 0,
				y: 0,
				tablabel: '往返程'
			};
		},
		created(props) {
			//生命周期里接收参数
			that = this;
			that.TicketSearchInfo = ticketSearchHelper.GetTicketSearchInfo();
		},
		mounted() {
			// console.log(1);
			window.addEventListener('scroll', that.handleScroll); // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
		},
		methods: {
			bindShowIM() {
				window.open(webconfig.yqfComApiurl+'/AjaxData/JumpToFeiFei');
			},

			handleClick(tab, event) {
				// console.log(tab.label, event);
				this.showCity1 = false;
				this.tablabel = tab.label;
			},
			searchInput(e) {
				console.log(e);
				this.$refs.cityAssembly.searchInput(e);
			},
			bindShowSelectCity(e, cityType) {
				that.cityType = cityType;
				that.showCity1 = true;
				var rect = e.target.getBoundingClientRect();
				// console.log(rect);
				that.x = rect.left - 200;
				that.y = rect.top;
				// console.log(window.pageYOffset + '1');
				// console.log(this.y);
				// e.currentTarget.select();
			},
			//选择城市
			bindShowCity(e, cityIdx, cityType) {
				if (that.TicketSearchInfo.IsChange) {
					return;
				}

				that.cityIdx = cityIdx;
				that.cityType = cityType;
				this.showCity1 = true;
				var rect = e.target.getBoundingClientRect();
				// console.log(rect);
				that.x = rect.left;
				that.y = rect.top;
				// console.log(window.pageYOffset + '1');
				// console.log(this.y);
				e.currentTarget.select();
			},
			handleScroll() {
				// if (that.TicketSearchInfo.Dual == 2) {
				// 	var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
				// } else {
				// 	var rect = document.querySelector('#scrollCity').getBoundingClientRect();
				// }
				if (that.cityType == 0) {
					if (that.tablabel == '') {
						var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
					} else {
						var rect = document.querySelector('#scrollCity').getBoundingClientRect();
					}
				} else if (that.cityType == 1) {
					if (that.tablabel == '') {
						var rect = document.querySelector('#scrollCity1' + that.cityIdx).getBoundingClientRect();
					} else {
						var rect = document.querySelector('#scrollCity1').getBoundingClientRect();
					}

				} else if (that.cityType == -1) {
					var rect = document.querySelector('#scrollCity_sp').getBoundingClientRect();
				}
				//scrollCity_sp

				that.y = rect.top;
				// console.log(window.pageYOffset + '1');
			},
			clickCityDel() {
				this.showCity1 = false;
			},
			//切换城市
			bindQieHuan(idx) {
				let ticketSearchInfo = that.TicketSearchInfo;
				that.TicketSearchInfo = ticketSearchHelper.QieHuan(ticketSearchInfo, idx);
			},
			//选择城市
			bindSelectCity(cityModel) {
				that.showCity1 = false;

				var cityIdx = that.cityIdx;
				var cityType = that.cityType;
				console.log(cityIdx);
				console.log(cityType);
				if (cityType == -1) {
					that.$emit('bindSelectCity', cityModel);
				} else {
					var TicketSearchInfo = that.TicketSearchInfo;
					that.TicketSearchInfo = ticketSearchHelper.SelectCity(TicketSearchInfo, cityType, cityIdx, cityModel);
				}
			},
			//选择日期
			showCalendar(dateIdx) {
				console.log(dateIdx);
				if (that.TicketSearchInfo.Dual == 0 && dateIdx == 1) {
					return;
				}
				that.dateIdx = dateIdx;
				this.showDate = true;
			},
			closeCalener() {
				this.showDate = false;
			},
			//选择日期
			bindSelectDate(dateModel) {
				console.log(dateModel);
				that.showDate = false;
				that.TicketSearchInfo = ticketSearchHelper.SelectDate(that.TicketSearchInfo, that.dateIdx, dateModel);
			},
			//添加航段
			bindAddSegment() {
				that.TicketSearchInfo = ticketSearchHelper.AddSegment(that.TicketSearchInfo);
			},
			bindDelSegment(idx) {
				that.TicketSearchInfo = ticketSearchHelper.DelSegment(that.TicketSearchInfo, idx);
			},
			//搜索
			bindSearch: function(e) {
				var TicketSearchInfo = that.TicketSearchInfo;
				if (TicketSearchInfo.Airline instanceof Array) {
					TicketSearchInfo.Airline = TicketSearchInfo.Airline[0];
				}
				flightApi.GetTicketSearch({
						ticketsearchinfo: JSON.stringify(TicketSearchInfo)
					},
					function(result) {
						console.log(result);
						if (result.code == 0) {
							TicketSearchInfo = result.data.TicketSearchInfo;
							console.log(TicketSearchInfo);
							setTicketSearchInfo(TicketSearchInfo);
							// that.$router.push({ path: '/IntlAir/list' });
							if (TicketSearchInfo.IsChinaCity) {
								that.$router.push({
									path: '/Flight/ShowDomesticFlight'
								});
							} else {
								that.$router.push({
									path: '/Flight/showIntlflight'
								});
							}
						} else {
							// that.$message.error(result.msg);
							that.$alert(result.msg);
						}
					}
				);
			}
		}
	};

	//获取查询机票的信息
	function getTicketSearchInfoJSON() {
		flightApi.GetTicketSearch(null, function(result) {
			console.log(result);
			if (result.code == 0) {
				console.log(result.data.TicketSearchInfo);
				that.TicketSearchInfo = result.data.TicketSearchInfo;
				that.TicketSearchInfo.OrderNo = '';
				that.TicketSearchInfo.IsChange = false;
				setTicketSearchInfo(that.TicketSearchInfo);
				// that.dualType = TicketSearchInfo.Dual;
			}
		});
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
	@import '../../../style/home/home.scss';
</style>
